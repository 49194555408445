

































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import WorkspaceForm from '@/components/workspace/WorkspaceForm.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogWorkspaceCreate',
  components: {
    CompositeDialog,
    AlertError,
    WorkspaceForm
  },
  setup() {
    const dialogId: DialogId = 'workspace-create';
    const rDialog = ref<any>(null);

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const workspaceCreateRequest = requestModule.useAuthenticatedRequest(`${apiConfig.default}/workspaces`, {
      axios: {
        method: 'POST'
      }
    });

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (!value) {
            workspaceCreateRequest.cancel();

            nextTick(() => {
              workspaceCreateRequest.clear();
            });
          }
        }
      );
    });

    function submitForm() {
      rDialog.value.$children[0].$children[0].$children[0].$children[1].submit();
    }

    function createWorkspace(data: any) {
      workspaceCreateRequest.request({
        axios: {
          method: 'POST',
          data
        }
      });
    }

    function closeDialog() {
      rDialog.value.close();
    }

    const isDisabled = computed(() => {
      return workspaceCreateRequest.isPending.value;
    });

    const isSuccess = computed(() => {
      return !workspaceCreateRequest.error.value && !!workspaceCreateRequest.data.value;
    });

    watch(isSuccess, (value) => {
      if (value) {
        /* busModule.publish(
          busModule.events.workspaceCreated({
            workspace: workspaceCreateRequest.data.value
          })
        );

        closeDialog(); */

        const to_uri = new URL('https://www.digitsolepro.com/pricing');
        const redirect_uri = new URL(window.location.origin);

        redirect_uri.searchParams.append('name', workspaceCreateRequest.data.value.name);
        redirect_uri.searchParams.append('color', workspaceCreateRequest.data.value.color);

        to_uri.searchParams.append('redirect_uri', redirect_uri.href);

        window.location.assign(to_uri.href);
      }
    });

    return {
      // References
      rDialog,
      // Values
      dialogId,
      workspaceCreateRequest,
      // Methods
      submitForm,
      createWorkspace,
      closeDialog,
      // Flags
      isDisabled,
      isSuccess
    };
  }
});
