



































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useNotifications } from '@/utils/notifications.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'LayoutDefaultNavbarNotifications',

  setup() {
    const menu = ref(false);

    const { notifications, clickNotification, deleteNotifications, hideNotifications } = useNotifications();

    const noCustomNotifications = computed(() =>
      notifications.value
        .filter((notification) => notification.data && !('custom' in notification.data!))
        .reverse()
    );

    function deleteNotificationsAndClose() {
      menu.value = false;
      deleteNotifications();
    }

    watch(menu, (state) => {
      if (state) hideNotifications();
    });

    return {
      // Values
      menu,
      noCustomNotifications,
      // Methods
      clickNotification,
      deleteNotificationsAndClose
    };
  }
});
