









































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, ref, Ref, watch } from '@vue/composition-api';
import { Component } from 'vue';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService, usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import OnboardingImage from '@/components/onboarding/OnboardingImage.vue';
import InvitePractitioners from '@/components/onboarding/InvitePractitioners.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// Import types ------------------------------------------------------------------------------------
import {
  AbilityAction,
  AbilitySubject
} from '@digitsole/blackburn-entities/dist/entities/ability/types/ability.enums';
// -------------------------------------------------------------------------------------------------
export enum EStep {
  Dashboard = 'dashboard',
  App = 'app',
  Results = 'results',
  InsertDsPod = 'insertDsPod',
  InvitePractitioners = 'invitePractitioners'
}

export interface ISteps {
  id: EStep;
  img?: string;
  component: Component;
  disabled?: boolean;
}

export default defineComponent({
  name: 'DialogOnboarding',
  components: {
    OnboardingImage,
    InvitePractitioners,
    AlertError
  },
  setup(_, { root }) {
    const { cdn } = useCDN();

    const workspacesService = usePodocoreModuleService('workspaces');
    const requestModule = usePodocoreModule('request');
    const aclModule = usePodocoreModule('acl');
    const busModule = usePodocoreModule('bus');

    const canEndOnboarding = ref(false);

    const hasBeenOnboarded = computed(
      () => (workspacesService.state.value as any).context.current.onboardedAt
    );

    const workspace = computed(() => (workspacesService.state.value as any).context.current);

    const workspaceRequest = ref();

    onMounted(() => {
      watch(
        workspace,
        () => {
          workspaceRequest.value = requestModule.useAuthenticatedRequest(
            `${apiConfig.default}/workspaces/${workspace.value.cuid}`,
            {
              axios: {
                method: 'PATCH',
                data: {
                  onboardedAt: new Date()
                }
              }
            }
          );
        },
        { immediate: true }
      );
    });

    const remainingFreeUserSlots = computed(() => {
      return (
        aclModule.reactiveBuild.relevantRuleFor(AbilityAction.Invite, AbilitySubject.Workspace)?.conditions
          ?.remainingFreeUserSlots ?? 0
      );
    });

    const currentStep = ref(1);
    const steps: Ref<ISteps[]> = ref([
      {
        id: EStep.Dashboard,
        img: 'product_presentation_dashboard.svg',
        component: OnboardingImage
      },
      {
        id: EStep.App,
        img: 'product_presentation_app.svg',
        component: OnboardingImage
      },
      {
        id: EStep.Results,
        img: 'product_presentation_results.svg',
        component: OnboardingImage
      },
      {
        id: EStep.InsertDsPod,
        img: 'product_presentation_soles.svg',
        component: OnboardingImage
      },
      {
        id: EStep.InvitePractitioners,
        component: InvitePractitioners,
        disabled: remainingFreeUserSlots.value === 0
      }
    ]);

    busModule.subscribe(busModule.events.workspaceChanged, () => {
      currentStep.value = 1;
    });

    const enabledSteps = computed(() => steps.value.filter((step) => !step.disabled));

    const next = () => {
      if (currentStep.value < enabledSteps.value.length) currentStep.value += 1;
    };

    const isSuccess = computed(() => {
      return !workspaceRequest.value?.error && !!workspaceRequest.value?.data;
    });

    watch(isSuccess, (value) => {
      if (value) {
        workspacesService.send({
          type: 'FETCH',
          data: {
            workspaceCuid: workspace.value.cuid
          }
        } as any);
      }
    });

    const endOnboarding = () => {
      workspaceRequest.value?.request();
    };

    return {
      // Values
      hasBeenOnboarded,
      currentStep,
      enabledSteps,
      canEndOnboarding,
      workspace,
      workspaceRequest,
      // Methods
      cdn,
      next,
      endOnboarding
    };
  }
});
