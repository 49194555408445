




// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, onMounted } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'OnboardingImage',
  props: {
    img: {
      type: String,
      required: true
    }
  },
  setup(_, { emit }) {
    const { cdn } = useCDN();

    onMounted(() => {
      emit('canEndOnboarding');
    });

    return {
      cdn
    };
  }
});
