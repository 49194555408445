










// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, watch } from '@vue/composition-api';
import { isNil } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import DialogTermsAndConditions from '@/components/dialogs/DialogTermsAndConditions.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  components: { DialogTermsAndConditions },
  setup() {
    const busModule = usePodocoreModule('bus');

    const doctorService = usePodocoreModuleService('doctor');

    const documents = computed(() => (doctorService.state.value.context as any).doctor._unconsentedDocuments);

    const currentDocument = computed(() => documents.value[0]);

    function createWatcher() {
      console.log('🔍 Create watcher');

      watch(
        currentDocument,
        (currentDocument_) => {
          if (currentDocument_) {
            busModule.publish(
              busModule.events.openDialog({
                id: 'terms-and-conditions',
                meta: { consentDocumentCuid: currentDocument_.cuid }
              })
            );
          }
        },
        { immediate: true }
      );
    }

    onMounted(() => {
      if (isNil(sessionStorage.getItem('saas'))) {
        createWatcher();
      } else {
        const unsubscribe = busModule.useEventSubscriber(busModule.events.dialogClosed, ({ payload }) => {
          if (payload?.id === 'saas-checkout') {
            createWatcher();
            unsubscribe();
          }
        });
      }
    });

    return {
      currentDocument
    };
  }
});
