





















// TODO : Add Element components
// Import vendors -------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import LayoutDefaultNavbarWorkspace from '@/layouts/default/components/navbar/LayoutDefaultNavbarWorkspace.vue';
import LayoutDefaultNavbarBreadcrumbs from '@/layouts/default/components/navbar/LayoutDefaultNavbarBreadcrumbs.vue';
import LayoutDefaultNavbarSearchPatient from '@/layouts/default/components/navbar/LayoutDefaultNavbarSearchPatient.vue';
import LayoutDefaultNavbarAccount from '@/layouts/default/components/navbar/LayoutDefaultNavbarAccount.vue';
import LayoutDefaultNavbarNotifications from '@/layouts/default/components/navbar/LayoutDefaultNavbarNotifications.vue';
import LayoutDefaultNavbarSignOut from '@/layouts/default/components/navbar/LayoutDefaultNavbarSignOut.vue';

export default defineComponent({
  name: 'LayoutDefaultNavbar',
  components: {
    LayoutDefaultNavbarWorkspace,
    LayoutDefaultNavbarBreadcrumbs,
    LayoutDefaultNavbarSearchPatient,
    LayoutDefaultNavbarAccount,
    LayoutDefaultNavbarNotifications,
    LayoutDefaultNavbarSignOut
  }
});
