


































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useFormFieldText, useFormObserver, useForm, cleanFieldValue } from '@/utils/forms.utils';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import FormFieldText from '@/components/forms/FormFieldText.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'InvitePractitioners',
  components: {
    FormFieldText,
    AlertSuccess,
    AlertError
  },
  props: {
    workspace: {
      type: Object,
      required: true
    }
  },
  setup(properties, { emit }) {
    const rObserver = useFormObserver();
    const showForm = ref(true);
    const email1 = useFormFieldText<string>({
      key: 'email1',
      type: 'email',
      value: '',
      rules: {
        email: true
      }
    });

    const email2 = useFormFieldText<string>({
      key: 'email2',
      type: 'email',
      value: '',
      rules: {
        email: true
      }
    });

    const requestModule = usePodocoreModule('request');

    const workspaceInviteMembersRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/workspaces/${properties.workspace.cuid}/members/invite`,
      {
        axios: {
          method: 'POST'
        }
      }
    );

    const { submit } = useForm(
      {
        email1,
        email2
      },
      rObserver,
      () => {
        const data = Object.values(
          Object.fromEntries(Object.entries(getFields()).filter(([_, v]) => v != null))
        );
        if (!data.length) return;

        const redirect_uri = new URL(window.location.origin);

        redirect_uri.pathname = '/join-workspace';

        workspaceInviteMembersRequest.request({
          axios: {
            method: 'POST',
            data: {
              usersEmails: data,
              redirectUrl: redirect_uri.href
            }
          }
        });
      }
    );

    const isDisabled = computed(() => {
      return workspaceInviteMembersRequest.isPending.value || isSuccess.value;
    });

    const isSuccess = computed(() => {
      return !workspaceInviteMembersRequest.error.value && !!workspaceInviteMembersRequest.data.value;
    });

    watch(isSuccess, (value) => {
      if (value) emit('canEndOnboarding');
    });

    function getFields() {
      return {
        email1: cleanFieldValue(email1),
        email2: cleanFieldValue(email2)
      };
    }

    return {
      email1,
      email2,
      showForm,
      rObserver,
      isDisabled,
      isSuccess,
      workspaceInviteMembersRequest,
      submit,
      emit
    };
  }
});
