import { render, staticRenderFns } from "./LayoutDefaultNavbarNotifications.vue?vue&type=template&id=67b5eee7&scoped=true&"
import script from "./LayoutDefaultNavbarNotifications.vue?vue&type=script&lang=ts&"
export * from "./LayoutDefaultNavbarNotifications.vue?vue&type=script&lang=ts&"
import style0 from "./LayoutDefaultNavbarNotifications.vue?vue&type=style&index=0&id=67b5eee7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b5eee7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_a266eb3209234f15fc02fe57f54d2cc5/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VCard,VDivider,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu,VSubheader,VTooltip})
