




















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocore } from '@/plugins/podocore';

export default defineComponent({
  name: 'LayoutDefaultNavbarSignOut',

  setup() {
    // TODO : Static into dynamic
    const isPending = ref(false);

    // --------------------------------------------------------------------------------------------

    const authService = useService(usePodocore().getModuleService('auth'));

    function signOut() {
      authService.send({ type: 'SIGN_OUT' });
    }

    return {
      isPending,
      //
      authService,
      signOut
    };
  }
});
