





















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, onMounted, ref, toRefs } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useFormFieldText, useFormObserver, cleanFieldValue, useForm } from '@/utils/forms.utils';
// Import components -------------------------------------------------------------------------------
import FormFieldText from '@/components/forms/FormFieldText.vue';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { WorkspaceEntity } from '@/plugins/podocore/repositories/workspaces.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'WorkspaceForm',
  props: {
    workspace: {
      type: Object as PropType<WorkspaceEntity | null>,
      default: null
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FormFieldText
  },
  setup(properties, { emit }) {
    const rObserver = useFormObserver();

    const { workspace } = toRefs(properties);

    const name = useFormFieldText<string>({
      key: 'name',
      value: '',
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const color = ref('#004c92');

    const { submit } = useForm(
      {
        name,
        color
      },
      rObserver,
      () => {
        emit('submit', getFields());
      }
    );

    function getFields() {
      return {
        name: cleanFieldValue(name),
        color: cleanFieldValue(color)
      };
    }

    onMounted(() => {
      if (workspace.value) {
        name.value = workspace.value.name;
        color.value = workspace.value.color;
      }
    });

    return {
      // References
      rObserver,
      // Form fields
      name,
      color,
      // Methods
      submit
    };
  }
});
