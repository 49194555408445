













// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'LayoutDefaultNavbarAccount',
  setup() {
    const doctorService = usePodocoreModuleService('doctor');

    const initials = computed(() => {
      const doctor = (doctorService.state.value as any).context.doctor;
      return doctor?.profile.firstName.slice(0, 1) + doctor?.profile.lastName.slice(0, 1);
    });

    return {
      initials
    };
  }
});
