





























































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, reactive } from '@vue/composition-api';
import { useLocalStorage } from '@vueuse/core';
import { capitalize, compact } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils ------------------------------------------------------------------------------------
import { useI18n } from '@/utils/i18n.utils';
import { useAnalytics } from '@/utils/analytics.utils';
import { useCDN } from '@/utils/cdn.utils';
import { useVersion } from '@/utils/version.utils';
// Import configurations ---------------------------------------------------------------------------
import { versionConfig } from '@/config/version.config';
// Export / declare types --------------------------------------------------------------------------
type TLinks = {
  title: string;
  icon: string;
  disabledInDemo?: boolean;
};
// -------------------------------------------------------------------------------------------------

/**
 * Sidebar of "default" layout
 */
export default defineComponent({
  name: 'LayoutDefaultSidebar',
  setup(_, { root }) {
    const { currentVersion } = useVersion();
    const { currentLang } = useI18n();
    const { trackSuccess } = useAnalytics();
    const { cdn } = useCDN();

    const stage = process.env['BLACKBURN__STAGE'];

    const isWideScreen = computed(() => {
      return root.$vuetify.breakpoint.mdAndUp;
    });

    // TODO : Static into dynamic
    const notUpdatedOrCalibratedSoles = reactive({
      length: 0
    });
    const updateVersion = ref(
      compact([
        currentVersion.value,
        stage && stage !== 'production' ? stage : undefined,
        versionConfig.default
      ]).join('-')
    );

    const links = computed<Record<string, TLinks>>(() => ({
      home: {
        title: capitalize(String(i18n.t('commons.standards.home'))),
        icon: 'home'
      },
      patients: {
        title: capitalize(String(i18n.tc('commons.standards.patient', 2))),
        icon: 'users',
        disabled: !root.$can('read', 'patient')
      },
      equipment: {
        title: capitalize(String(i18n.tc('commons.standards.equipment', 2))),
        icon: 'suitcase'
      },
      saas: {
        title: capitalize(String(i18n.tc('saas.page.title', 2))),
        icon: 'stars',
        disabled: !root.$can('update', 'saas')
      },
      settings: {
        title: capitalize(String(i18n.tc('commons.standards.setting', 2))),
        icon: 'cog'
      }
    }));

    const miniVariant = useLocalStorage('miniVariant', false);

    const supportLink = computed(() => {
      switch (currentLang.value) {
        // case 'fr':
        //   return 'https://www.podosmart.tech/fr/support-clients';
        default:
          return 'https://www.digitsolepro.com/contact';
      }
    });

    function toggleMiniVariant() {
      miniVariant.value = !miniVariant.value;
    }

    return {
      // Values
      updateVersion,
      links,
      supportLink,
      // Flags
      isWideScreen,
      notUpdatedOrCalibratedSoles,
      miniVariant,
      // Methods
      cdn,
      toggleMiniVariant,
      // Utils
      trackSuccess
      // analytics
    };
  }
});
