



































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { detect } from 'detect-browser';

export default defineComponent({
  name: 'ElementNotCompatibleBrowserSnackbar',
  setup() {
    const snackbar = ref(true);

    const browser = computed(() => {
      const b = detect();
      return b?.name || null;
    });
    const isCompatibleBrowser = computed(() => {
      // CHeck browser
      switch (browser.value) {
        case 'chrome':
        case 'firefox':
        case 'safari':
        case 'edge-chromium':
        case 'opera':
          return true;
        default:
          return false;
      }
    });
    return { snackbar, isCompatibleBrowser };
  }
});
