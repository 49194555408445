




// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, ref } from '@vue/composition-api';
import { capitalize } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
// Import types ------------------------------------------------------------------------------------
import type { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import type { Patient } from '@/plugins/podocore/repositories/patients.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'LayoutDefaultNavbarBreadcrumbs',
  setup(_, { root }) {
    const { matched } = useRoute();

    const busModule = usePodocoreModule('bus');

    const currentPatient = ref<Entity<Patient> | null>(null);

    const breadcrumbs = computed(() => {
      return matched.value
        .filter((route) => route.meta.breadcrumb)
        .map((route: any) => {
          if (route.name === 'patient') {
            return {
              text: patientName.value ?? `${root.$t('commons.standards.loading')}...`,
              to: { name: route.name },
              exact: true
            };
          } else {
            return {
              text: route.meta.breadcrumb(),
              to: { name: route.name },
              exact: true
            };
          }
        });
    });

    busModule.useEventSubscriber(busModule.events.patientLoaded, ({ payload }) => {
      currentPatient.value = payload.patient;
    });

    busModule.useEventSubscriber(busModule.events.patientPatched, ({ payload }) => {
      currentPatient.value = payload.patient;
    });

    busModule.useEventSubscriber(busModule.events.patientUnloaded, () => {
      currentPatient.value = null;
    });

    const patientName = computed(() => {
      const f = currentPatient.value?.infos.firstName;
      const l = currentPatient.value?.infos.lastName;
      if (f?.length && l?.length) return `${capitalize(f)} ${capitalize(l.slice(0, 1))}.`;
      return undefined;
    });

    return {
      breadcrumbs
    };
  }
});
