














































// Import vendors ----------------------------------------------------------------------------------
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch
} from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
import { pickBy, identity } from 'lodash';
// Import config -----------------------------------------------------------------------------------
import { saasConfig } from '@/config/saas.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogSaasCheckout',
  components: {
    CompositeDialog,
    AlertError
  },
  setup(_, { root }) {
    const dialogId: DialogId = 'saas-checkout';
    const rDialog = ref<any>(null);

    const requestModule = usePodocoreModule('request');
    const workspacesModule = usePodocoreModule('workspaces');

    const workspacesService = usePodocoreModuleService('workspaces');
    const doctorService = usePodocoreModuleService('doctor');

    const currentWorkspace = computed(() => {
      return (workspacesService.state.value as any).context.current;
    });

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    const saasCheckoutRequest = requestModule.useAuthenticatedRequest(
      `${saasConfig.default}/widget/subscription/createByApplicationWorkspaceId/${currentWorkspace.value.cuid}`
    );

    // https://f1l2qwhll5.execute-api.eu-central-1.amazonaws.com/develop/v1/widget/subscription/manageByApplicationWorkspaceId/cktilecm00001kcum771u7zxd

    const checkoutInstance = reactive({
      error: null,
      isLoaded: false,
      isPending: false,
      isSuccess: false,
      isClosed: false
    });

    onMounted(() => {
      const saas = sessionStorage.getItem('saas');

      if (saas) {
        const { planId, addons, couponIds } = JSON.parse(saas);

        rDialog.value.open(dialogId);

        const data = {
          planId,
          addons,
          couponIds,
          billingAddress: {
            firstName: doctor.value.profile.firstName,
            lastName: doctor.value.profile.lastName
          }
        };
        const cleanedData = pickBy(data, identity);

        saasCheckoutRequest.request({
          axios: {
            method: 'POST',
            data: cleanedData
          }
        });
      }

      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (!value) {
            saasCheckoutRequest.cancel();

            nextTick(() => {
              saasCheckoutRequest.clear();
            });
          }
        }
      );
    });

    onBeforeUnmount(() => {
      workspacesModule.chargebee.logout();
    });

    function closeDialog() {
      rDialog.value.close(dialogId);

      sessionStorage.removeItem('saas');
    }

    const isDisabled = computed(() => {
      return saasCheckoutRequest.isPending.value;
    });

    const hasError = computed(() => {
      return saasCheckoutRequest.error.value;
    });

    watch(hasError, (value) => {
      if (value) closeDialog();
    });

    const isSuccess = computed(() => {
      return !saasCheckoutRequest.error.value && !!saasCheckoutRequest.data.value;
    });

    watch(isSuccess, (value) => {
      if (value) {
        const checkout = saasCheckoutRequest.data.value;

        checkoutInstance.isPending = true;

        workspacesModule.chargebee.openCheckout({
          hostedPage() {
            return Promise.resolve(checkout);
          },
          loaded() {
            checkoutInstance.isPending = false;
            checkoutInstance.isLoaded = true;
          },
          success() {
            checkoutInstance.isSuccess = true;
          },
          error(error: any) {
            checkoutInstance.error = error;
          },
          close() {
            checkoutInstance.isClosed = true;
          }
        });
      }
    });

    watch(checkoutInstance, (value) => {
      if (value.isClosed) {
        closeDialog();
      }
      if (value.isSuccess) {
        // closeDialog(); don't close the dialog to avoid flash refresh

        sessionStorage.removeItem('saas');

        workspacesModule.chargebee.closeAll();

        setTimeout(() => {
          window.location.reload();
        }, 10000);
      }
    });

    function clearAndReload() {
      sessionStorage.removeItem('saas');

      window.location.assign(root.$router.resolve({ name: 'home' }).href);
    }

    return {
      // References
      rDialog,
      // Values
      dialogId,
      saasCheckoutRequest,
      checkoutInstance,
      // Methods
      closeDialog,
      clearAndReload,
      // Flags
      isDisabled,
      isSuccess
    };
  }
});
